export default defineNuxtRouteMiddleware(to => {
  const { token, isExpired } = useAuth()

  if (to.name === 'admin-auth-login' || import.meta.server) {
    return
  }

  if (!token.value || !isExpired.value) {
    return navigateTo(`/admin/auth/login?from=${to.fullPath}`)
  }
})
